<template>
  <header class="header header-transparent" v-bind:class="{'header-small': scrolled, 'header-shadow': scrolled}">
    <div class="container-fluid navbar navbar-light navbar-expand-lg">
      <!-- Brand -->
      <div class="navbar-brand">
        <span class="logo"/>
        <a id="top" class="inner-brand" href="/">Kumojin</a>
      </div>

      <!-- Collapse button -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler"
              aria-controls="navbarToggler" aria-expanded="false" :aria-label="$t('header.toggleNavigation')">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navigation-->
      <div class="inner-navigation">
        <div class="inner-nav onepage-nav collapse navbar-collapse" id="navbarToggler">
          <ul class="navbar-nav">
            <li class="nav-item"><a class="nav-link" href="#top"><span
              class="menu-item-span">{{$t('header.home')}}</span></a></li>
            <li class="nav-item"><a class="nav-link" href="#services"><span class="menu-item-span">{{$t('header.services')}}</span></a>
            </li>
            <li class="nav-item"><a class="nav-link" href="#team"><span
              class="menu-item-span">{{$t('header.team')}}</span></a></li>
            <li class="nav-item"><a class="nav-link" href="#contact"><span class="menu-item-span">{{$t('header.contact')}}</span></a>
            </li>
          </ul>
          <LanguageChanger class="lang-switch"/>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import LanguageChanger from '@/components/home/LanguageChanger.vue';

  export default {
    data() {
      return {
        scrolled: false
      };
    },
    name: 'Header',
    components: {
      LanguageChanger
    },
    methods: {
      handleScroll() {
        this.scrolled = window.scrollY > 5;
      }
    },
    created() {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    }
  };
</script>

<style>
  .header {
    position: fixed;
    min-height: 74px;
    width: 100%;
    transition: height 0.3s ease-out, background 0.3s ease-out,
    box-shadow 0.3s ease-out;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 1040;
  }

  .header.header-small {
    min-height: 60px;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.07);
  }

  .header .container-fluid {
    position: relative;
    height: 100%;
  }

  .navbar-brand::before {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    margin-right: -1px;
    content: "";
  }

  .navbar-brand {
    position: relative;
    height: 100%;
    float: left;
    z-index: 1;
    padding-left: 15px;
  }

  .navbar-brand .logo {
    background-image: url("/assets/logo-white.png");
    background-repeat: no-repeat;
    background-size: 80px;
    float: left;
    height: 80px;
    position: absolute;
    margin-top: -10px;
    vertical-align: middle;
    width: 80px;
  }

  .inner-brand {
    display: inline-block;
    vertical-align: middle;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 1.25rem;
    margin-left: 84px;
  }

  .inner-navigation {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }

  .inner-nav {
    vertical-align: middle;
    display: inline-block;
    height: 100%;
  }

  .inner-nav > ul {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  .inner-nav > ul > li {
    position: relative;
    display: block;
    height: 100%;
    float: left;
    padding: 0 0.625rem;
    margin: 0;
  }

  .inner-nav > ul > li > a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    padding: 0 7px;
    letter-spacing: 0.05625rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.75rem;
  }

  .inner-nav > ul > li > a > .menu-item-span {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    display: block;
    top: 50%;
  }

  .header.header-transparent {
    background: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .header.header-transparent .inner-brand {
    color: #fff;
  }

  .header.header-transparent .inner-nav > ul > li > a {
    color: #fff;
  }

  .header.header-transparent .inner-nav > ul > li > a:hover,
  .header.header-transparent .inner-nav > ul > li > a.active {
    color: rgba(255, 255, 255, 0.7);
  }

  .header.header-transparent .inner-nav > ul > li > a:hover::after {
    background: #fff;
    margin-left: 0;
    width: 100%;
    left: 0;
  }

  .header.header-transparent .inner-nav > ul > li > a::after {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    display: block;
    height: 1px;
    width: 20px;
    bottom: -1px;
    left: 50%;
    margin-left: -10px;
    content: "";
    transition: all 0.3s ease-out;
  }

  .header,
  .header.header-small {
    background: #fff;
    border-bottom: 1px solid rgba(240, 240, 240, 0.8);
  }

  .header .inner-brand,
  .header.header-small .inner-brand {
    color: #222;
  }

  .header.header-small .navbar-brand .logo {
    filter: invert(100%);
  }

  .header .inner-nav > ul > li > a,
  .header.header-small .inner-nav > ul > li > a {
    color: #222;
  }

  .header .inner-nav > ul > li > a:hover,
  .header .inner-nav > ul > li > a.active,
  .header.header-small .inner-nav > ul > li > a:hover,
  .header.header-small .inner-nav > ul > li > a.active {
    color: rgba(34, 34, 34, 0.65);
  }

  .header .inner-brand {
    color: #222;
  }

  .header .inner-nav > ul > li > a {
    color: #222;
  }

  .header .inner-nav > ul > li > a:hover,
  .header .inner-nav > ul > li > a.active {
    color: rgba(34, 34, 34, 0.65);
  }

  @media (min-width: 992px) {
    .header {
      height: 74px;
    }

    .inner-navigation {
      padding-right: 15px;
      position: absolute;
    }

    ul.navbar-nav {
      margin-left: auto;
      margin-right: auto;
    }

    ul.lang-switch {
      float: right;
      padding-right: 15px;
    }
  }

  @media (max-width: 991.98px) {
    .header,
    .header.header-small,
    .header.header-transparent {
      background: #fff;
      border-bottom: 1px solid rgba(240, 240, 240, 0.8);
    }

    .header.header-small .navbar-brand .logo,
    .header.header-transparent .navbar-brand .logo {
      filter: invert(100%);
    }

    .header .inner-brand,
    .header.header-transparent .inner-brand {
      color: #222;
    }

    .header .inner-nav > ul > li > a,
    .header.header-transparent .inner-nav > ul > li > a {
      color: #fff;
    }

    .header .inner-nav > ul > li > a:hover,
    .header.header-transparent .inner-nav > ul > li > a:hover {
      color: rgba(255, 255, 255, 0.7);
    }

    .inner-nav {
      display: block;
      padding: 20px 0;
    }

    .inner-nav > ul {
      height: auto;
    }

    .inner-nav > ul > li {
      float: none;
    }

    .inner-nav > ul > li > a {
      padding: 10px 0;
      font-size: 1rem;
    }

    .inner-nav > ul > li > a > .menu-item-span {
      color: #222;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      top: 0;
    }
  }
</style>
