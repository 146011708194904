<template>
  <a class="scroll-top scroll-top-visible" href="#top" v-on:click="scrollTop">
    <i class="fas fa-angle-up"></i>
  </a>
</template>

<script>
export default {
  methods: {
    scrollTop() {
      scrollTo(0, 1000);
    }
  }
};

const scrollTo = function(to, duration) {
  const element = document.scrollingElement || document.documentElement;
  const start = element.scrollTop;
  const change = to - start;
  const startDate = +new Date();

  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = function(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const animateScroll = function() {
    const currentDate = +new Date();
    const currentTime = currentDate - startDate;

    element.scrollTop = parseInt(
      easeInOutQuad(currentTime, start, change, duration)
    );

    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    } else {
      element.scrollTop = to;
    }
  };

  animateScroll();
};
</script>

<style>
.scroll-top {
  position: fixed;
  display: block;
  bottom: 1.5625rem;
  right: 1.5625rem;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
  height: 2.375rem;
  width: 2.375rem;
  line-height: 2.25rem;
  text-align: center;
  font-size: 0.875rem;
  border-radius: 100%;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
  transition: 0.5s all ease;
  -webkit-transform: translateY(4.25rem);
  transform: translateY(4.25rem);
  color: #788487;
}

.scroll-top.scroll-top-visible {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.scroll-top:hover {
  -webkit-transform: translateY(-0.3125rem);
  transform: translateY(-0.3125rem);
}

@media (max-width: 767.98px) {
  .scroll-top {
    display: none;
  }
}
</style>
