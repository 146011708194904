<template>
  <div class="home">
    <Hero/>
    <Services/>
    <About/>
    <Team/>
    <Contact/>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Hero from '@/components/home/Hero.vue';
  import Services from '@/components/home/Services.vue';
  import About from '@/components/home/About.vue';
  import Team from '@/components/home/Team.vue';
  import Contact from '@/components/home/Contact.vue';

  export default {
    name: 'home',
    components: {
      Hero,
      Services,
      About,
      Team,
      Contact
    }
  };
</script>

<style>
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  .lead {
    font-size: 0.9375rem;
    font-weight: 400;
    letter-spacing: 0.01875rem;
  }

  .module {
    background-position: 50%;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: cover;
    padding: 80px 0 60px;
  }

  .bg-gray {
    background-color: #f8f8f8;
  }
</style>
