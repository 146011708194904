import Vue from "vue";
import VueI18n from "vue-i18n";
import browserLocale from "browser-locale";

import App from "./App.vue";
import router from "./router";
import translations from "./i18n/translations";

Vue.config.productionTip = false;
Vue.use(VueI18n);

const locale = ["en", "fr"].includes(browserLocale().split("-")[0])
  ? browserLocale().split("-")[0]
  : "en";

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: "en",
  messages: translations
});

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount("#app");
