export const Team = [
  {
    name: 'Julien Maitrehenry',
    role: 'CEO & Cloud Architect',
    image: 'assets/team/jmaitrehenry.jpg',
    networks: {
      linkedIn: 'https://linkedin.com/in/jmaitrehenry'
    }
  },
  {
    name: 'Lukasz Kokot',
    role: 'CTO & Cloud Architect',
    image: 'assets/team/lkokot.jpg',
    networks: {
      linkedIn: 'https://www.linkedin.com/in/lukasz-kokot/',
      github: 'https://github.com/lukaszkokot'
    }
  },
  {
    name: 'Alexandre Hamel',
    role: 'Cloud Developer',
    image: 'assets/team/ahamel.jpg',
    networks: {
      linkedIn: 'https://www.linkedin.com/in/alexandre-hamel-4392408b/',
    }
  },
  {
    name: 'Pierre Saussure',
    role: 'Cloud Developer',
    image: 'assets/team/psaussure.jpg',
    networks: {
      linkedIn: 'https://www.linkedin.com/in/pierre-saussure/',
      github: 'https://github.com/blackholegalaxy'
    }
  },
  {
    name: 'Faustine Mastrodicasa',
    role: 'Cloud Developer',
    image: 'assets/team/fmastrodicasa.jpg',
    networks: {
      linkedIn: 'https://www.linkedin.com/in/faustine-mastrodicasa-6999869b/',
    }
  },
  {
    name: 'Eric Le Donge',
    role: 'Cloud Developer',
    image: 'assets/team/eledonge.jpg',
    networks: {
      linkedIn: 'https://www.linkedin.com/in/ericledonge/',
    }
  },
  {
    name: 'Matthieu Hahn',
    role: 'Cloud Developer',
    image: 'assets/team/mhahn.jpg',
    networks: {
      linkedIn: 'https://www.linkedin.com/in/matthieu-hahn-751aa17a/',
      github: 'https://github.com/matthieuhahn'
    }
  },
];
