<template>
  <div class="col-lg-2 col-md-4">
    <div class="team-item">
      <div class="team-image"><img :src="image" :alt="name">
        <div class="team-wrap">
          <div class="team-content">
            <h6 class="team-name">{{ name }}</h6>
            <div class="team-role">{{ role }}</div>
          </div>
          <div class="team-content-social">
            <ul>
              <li v-if="networks.linkedIn">
                <a :href="networks.linkedIn" target="_blank" rel="noopener">
                  <i class="fab fa-linkedin"></i>
                </a>
              </li>
              <li v-if="networks.twitter">
                <a :href="networks.twitter" target="_blank" rel="noopener">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li v-if="networks.github">
                <a :href="networks.github" target="_blank" rel="noopener">
                  <i class="fab fa-github"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TeamPlayer',
    props: {
      name: {
        type: String,
        required: true
      },
      role: {
        type: String,
        required: true
      },
      image: {
        type: String,
        required: true
      },
      networks: {
        type: Object
      }
    },
  };
</script>

<style scoped>
  .team-item,
  .team-wrap,
  .team-content,
  .team-content-social {
    transition: all 0.4s ease-in-out;
  }

  .team-item {
    border-radius: 0.1875rem;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    margin: 0 0 30px;
    position: relative;
  }

  .team-item img {
    border-radius: 0.1875rem;
    width: 100%;
  }

  .team-wrap {
    background: rgba(34, 34, 34, 0.6);
    border-radius: 0.1875rem;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .team-content {
    bottom: 0;
    padding: 20px;
    position: absolute;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    width: 100%;
  }

  .team-name {
    font-size: 0.8125rem;
    margin-bottom: 2px;
  }

  .team-role {
    font-size: 0.75rem;
    opacity: 0.6;
  }

  .team-name,
  .team-role {
    color: #fff;
  }

  .team-content-social {
    list-style: none;
    margin: 0;
    padding: 20px;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  .team-content-social li {
    margin-bottom: 5px;
  }

  .team-content-social li a {
    color: #fff;
  }

  .team-content-social li a:hover {
    opacity: 0.6;
  }

  .team-item:hover {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }

  .team-item:hover .team-wrap {
    opacity: 1;
  }

  .team-item:hover .team-content,
  .team-item:hover .team-content-social {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
</style>
