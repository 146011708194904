<template>
  <div id="app">
    <Header />
    <div class="wrapper">
    <router-view/>
    </div>
    <Footer />
    <ScrollTop />
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Footer from "@/components/home/Footer.vue";
import ScrollTop from "@/components/home/ScrollTop.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    ScrollTop
  }
};
</script>

<style>
:root {
  --alabaster: #f8f8f8;
  --athens-gray: #f0f0f0;
  --wild-sand: #f4f4f4;
  --rolling-stone: #788487;
  --mine-shaft: #333;
  --black: #222;
  --brand: #505cfd;
  --new-gray: #d8d8d8;
  --new-white: #fff;
}

@-ms-viewport {
  width: device-width;
}

article,
footer,
header,
section {
  display: block;
}

body {
  font-family: "Poppins", Arial;
  font-size: 0.875rem;
  line-height: 1.8;
  color: #788487;
}

h1,
h2,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.625rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.3;
  color: #222;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
  list-style: none;
  padding-left: 0;
}

strong {
  font-weight: bolder;
}

a {
  color: #505cfd;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0416fc;
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

h1 {
  font-size: 2.625rem;
}

h2 {
  font-size: 2rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 0.875rem;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-4,
.col-md-6,
.col-md-8,
.col-md-9,
.col-md-12,
.col-lg-3,
.col-lg-6 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
