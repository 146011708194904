<template>
  <!-- <div class="inner-nav lang"> -->
    <ul>
      <li class="lang-item" v-if="$i18n.locale == 'en'" v-on:click="changeLocaleToFR">
        <a href="#">
          <span class="menu-item-span">
              <span class="lang">FR</span>
          </span>
        </a>
      </li>
      <li class="lang-item" v-if="$i18n.locale == 'fr'" v-on:click="changeLocaleToEN">
        <a href="#">
          <span class="menu-item-span">
              <span class="lang">EN</span>
          </span>
        </a>
      </li>
    </ul>
  <!-- </div> -->
</template>

<script>
export default {
  methods: {
    changeLocaleToFR() {
      this.$i18n.locale = "fr";
    },
    changeLocaleToEN() {
      this.$i18n.locale = "en";
    }
  }
};
</script>
