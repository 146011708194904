<template>
  <section class="module" id="team">
    <div class="container">
      <div class="row">
        <div class="col-md-6 m-auto text-center">
          <h1>{{ $t('homepage.team.title') }}</h1>
          <p class="lead">{{ $t('homepage.team.subtitle') }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="space" data-my="60px" style="margin-top: 60px;"></div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <TeamPlayer
          v-for="player in team"
          :key="player.name"
          :name="player.name"
          :role="player.role"
          :image="player.image"
          :networks="player.networks"
        />
      </div>

    </div>
  </section>
</template>

<script>
  import TeamPlayer from './TeamPlayer';
  import { Team } from '../../../public/assets/team/team';

  export default {
    name: 'Team',
    components: {
      TeamPlayer
    },
    data() {
      return {
        team: Team
      };
    }
  };
</script>
