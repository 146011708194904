const translations = {
  en: {
    header: {
      home: 'home',
      team: 'Team',
      contact: 'Contact',
      services: 'Services',
      toggleNavigation: 'Toggle navigation'
    },
    footer: {
      copyright: 'All Rights Reserved.',
      credits:
        'Some icons were made by <a href="https://www.flaticon.com/authors/gregor-cresnar" title="Gregor Cresnar">Gregor Cresnar</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> and are licensed under <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a>'
    },
    homepage: {
      about: {
        title: 'We are software engineers.',
        p1:
          'Engineering is at the heart of what we do, the core of Kumojin. Our team is composed of experienced members, knowledgeable in DevOps practices, cloud technologies and automation. Software development is evolving fast, and we strive to foster an innovative engineering culture by experimenting daily with the latest technologies and best practices in the field.',
        p2:
          'We are there to challenge the status quo. We help you adopt the best approaches in software development and delivery, while embedding culture change by up-skilling and empowering your people.'
      },
      services: {
        title: 'How we help our customers transforming themselves',
        cloudMigration: {
          title: 'Cloud platform build & migration',
          alt: 'cloud migration',
          line1: 'We provide a safe, repeatable, scalable and cost-effective ‘enterprise grade’ solution for building and migrating to cloud platforms.',
          line2: ' '
        },
        devops: {
          title: 'Enterprise DevOps transformation',
          alt: 'devops transformation',
          line1: 'We can help you leverage game-changing approaches to software delivery and IT operations at speed and scale.',
          line2: ' '
        },
        softwareDev: {
          title: 'Cloud-native software development',
          alt: 'software development',
          line1: 'We assist in moving to cloud-native architecture and software development practices, to accelerate time to market and organisational agility.',
          line2: ' '
        }
      },
      contact: {
        title: 'Let\'s talk',
        subtitle: 'Are you ready to transform your business?'
      },
      hero: {
        title: 'Start your digital transformation',
        subtitle:
          'Kumojin helps companies develop, integrate, and deploy applications faster'
      },
      team: {
        title: 'Our Team',
        subtitle: 'The people who are making it happen'
      }
    }
  },
  fr: {
    header: {
      home: 'Accueil',
      team: 'Équipe',
      contact: 'Contact',
      services: 'Services',
      toggleNavigation: 'Afficher/Masquer la navigation'
    },
    footer: {
      copyright: 'Tous droits réservés.',
      credits:
        'Certaines icônes ont été créées par <a href="https://www.flaticon.com/authors/gregor-cresnar" title="Gregor Cresnar">Gregor Cresnar</a> de <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> et sont utilisées sous licence <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a>'
    },
    homepage: {
      about: {
        title: 'Nous sommes plus qu\'une simple agence !',
        p1:
          'L\'ingénierie est au centre de nos activités, le cœur de Kumojin. Notre équipe est composée d\'experts DevOps, aguerris dans les technologies de l\'infonuagique et l\'automatisation. Le monde du logiciel évolue rapidement, et nous nous efforçons de favoriser une culture d\'ingénierie innovante en expérimentant les nouvelles technologies et pratiques de développement.',
        p2:
          'Nous sommes là pour défier le statu quo. Nous vous aidons à adopter les meilleures approches dans le développement et déploiement de logiciels, tout en renforçant les connaissances de vos équipes et en les responsabilisant.'
      },
      services: {
        title: 'Comment nous aidons nos clients à se transformer',
        cloudMigration: {
          title: 'Implantation et migration vers une plateforme infonuagique',
          alt: 'migration infonuagique',
          line1: 'Nous vous aidons à implanter une solution entreprise robuste, évolutive, reproductible et à moindre coût pour migrer votre infrastructure TI vers une plateforme infonuagique.',
          line2: ' '
        },
        devops: {
          title: 'Transformation de l\'entreprise vers une philosophie DevOps',
          alt: 'transformation devops',
          line1: 'Nous vous aidons à transformer votre approche en matière de déploiement de logiciels et d\'opérations TI pour qu\'elle devienne efficiente et évolutive',
          line2: ' '
        },
        softwareDev: {
          title: 'Développement de logiciels natifs infonuagiques',
          alt: 'developpement de logiciels',
          line1: 'Nous vous aidons à mettre en place une architecture et des pratiques de développement infonuagique natives, afin d\'améliorer l\'agilité organisationnelle et accélérer la mise sur marché.',
          line2: ' '
        }
      },
      contact: {
        title: 'Rencontrons nous',
        subtitle: 'Êtes vous prêt à transformer votre commerce ?'
      },
      hero: {
        title: 'Commencer votre transformation numérique',
        subtitle:
          'Kumojin aide les entreprises à développer, intégrer et déployer des applications plus rapidement'
      },
      team: {
        title: 'Notre équipe',
        subtitle: 'Les personnes qui rendent cela possible'
      }
    }
  }
};

export default translations;
